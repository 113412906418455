'use client';

import { FC, Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import KeyIcon from '@heroicons/react/24/outline/KeyIcon';
import Link from 'next/link';

import { useModalContext } from '@/context/modalContext';
import { sendClientSidePosthogEvent } from '@/utils/posthog';

import Button from '../button.component';

type UpgradeModalProps = {
  id: string;
};
const UpgradeModal: FC<UpgradeModalProps> = ({ id }) => {
  const { activeModal, closeModal } = useModalContext();

  const isActive = activeModal.id === id;
  const title: string = activeModal.data?.title || 'Upgrade Your Plan';
  const detail: string =
    activeModal.data?.detail ||
    'You need to upgrade your plan to access this feature.';

  return (
    <Transition.Root show={isActive} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-cyan-400">
                    <KeyIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-4">
                      <p
                        className="text-xs leading-[18px] text-black"
                        dangerouslySetInnerHTML={{ __html: detail }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex items-center justify-center sm:mt-6 sm:gap-3">
                  <Button
                    className="w-max"
                    type="button"
                    handleClick={closeModal}
                    variant="light"
                  >
                    Cancel
                  </Button>

                  <Link
                    href="/dashboard/profile/billing"
                    onClick={() => {
                      sendClientSidePosthogEvent('app_billing_click', {
                        from: 'upgrade_modal_click',
                      });

                      closeModal();
                    }}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-black bg-black px-4 py-1.5 text-sm font-medium text-white shadow-sm sm:mt-0 sm:w-fit sm:leading-6"
                  >
                    Upgrade
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default UpgradeModal;
